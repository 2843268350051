import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as dat from 'lil-gui'
import { gsap } from 'gsap'

// use this when importing images in order to find directory location
//import imageSource from './audio/lifeSystems.mp3'
//console.log(imageSource)


// don't delete
gsap.time


/**
 * Base
 */
// Debug
const gui = new dat.GUI()
gui.close()

// Canvas
const canvas1 = document.querySelector('canvas.webgl')


// Scene
const scene = new THREE.Scene()
const cubeTextureLoader = new THREE.CubeTextureLoader()
// hdri image order: px, nx, py, ny, pz, nz
const environmentMap = cubeTextureLoader.load([
    '/px-min.png',
    '/nx-min.png',
    '/py-min.png',
    '/ny-min.png',
    '/pz-min.png',
    '/nz-min.png'
])
environmentMap.encoding = THREE.sRGBEncoding
scene.background = environmentMap


/**
 * Overlay
 */
//TODO: gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0); use this to make a small overlay
//TODO: delete 'projectionMatrix * modelViewMatrix *' below in order to make the plane display in front of the rest of the scene
    //TODO: modify the first two parameters of PlaneGeometry to adjust the size
    //TODO: modify the color of the overlay by changing the first three parameters of 'vec4(1.0, 0.0, 0.0, 1.0)'
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
        {
            uAlpha: {value: 1}
        },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


/**
 * Points of interest
 */
// const points = [
//     {
//         position: new THREE.Vector3(13.85, 1.1, 4.02),
//         element: document.getElementById('point-1')
//     }
// ]

//console.log(points)

/**
 *  Non-gltf objects
 */
// const sphereGeometry = new THREE.SphereGeometry( .7, 16, 8 );
// const sphereMaterial = new THREE.MeshBasicMaterial( { color: 0x5E92F4, wireframe: true } );
// const sphereMesh = new THREE.Mesh( sphereGeometry, sphereMaterial );
// sphereMesh.encoding = THREE.sRGBEncoding
// sphereMesh.position.set(15,1.7,-0.84)
// sphereMesh.name = "hologram"
// scene.add( sphereMesh );

const spynetLoginTexture = new THREE.TextureLoader().load("/spynet_login2.jpg")
const spynetLoginPlane = new THREE.PlaneGeometry(4,4,1,1)
const spynetLoginMaterial = new THREE.MeshBasicMaterial({map: spynetLoginTexture, transparent: false})
const spynetLoginMesh = new THREE.Mesh(spynetLoginPlane, spynetLoginMaterial)
spynetLoginMesh.encoding = THREE.sRGBEncoding
spynetLoginMesh.name = "spynetLoginScreen"
spynetLoginMesh.rotation.set(0,1.569,0)
spynetLoginMesh.scale.set(0.63,0.31,1)
spynetLoginMesh.position.set(13.69,1.53,1.27)
scene.add(spynetLoginMesh)

const spynetProfileTexture = new THREE.TextureLoader().load("/spynet_profile2.jpg")
const spynetProfilePlane = new THREE.PlaneGeometry(4,4,1,1)
const spynetProfileMaterial = new THREE.MeshBasicMaterial({map: spynetProfileTexture})
const spynetProfileMesh = new THREE.Mesh(spynetProfilePlane, spynetProfileMaterial)
spynetProfileMesh.encoding = THREE.sRGBEncoding
spynetProfileMesh.name = "spynetProfileScreen"
spynetProfileMesh.rotation.set(0,1.569,0)
spynetProfileMesh.scale.set(0.63,0.31,1)
spynetProfileMesh.position.set(13.689, 1.53, 1.27)
scene.add(spynetProfileMesh)

/**
 * Models / Loaders
 */
//let sceneLoaded = false

//--------------------custom loading bar---------------------
const progressBar = document.getElementById('progress-bar')
const progressBarContainer = document.querySelector('.progress-bar-container')

const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        document.getElementById('btn').removeAttribute('hidden')
        document.getElementById('progress-bar').style.display = 'none'
        document.getElementById('label-bar1').style.display = 'none'
        document.getElementById('label-bar2').removeAttribute('hidden')
        document.getElementById('btn').onclick = function () {
            gsap.delayedCall(1,() =>
            {
                // duration parameter controls time for overlay fade in
                console.log('loaded')
                gsap.to(overlayMaterial.uniforms.uAlpha, {duration: 3, value: 0})
                progressBarContainer.style.display = 'none'

                tl.to(camera.position, {
                    duration: 4,
                    x: -25,
                    y: 4,
                    z: -18
                })
            })
        }
    },
    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        progressBar.value = (itemsLoaded / itemsTotal) * 100
    },
    // Timer for interest points. After 3 seconds, interest points will appear
    // window.setTimeout(() =>
    // {
    //   sceneLoaded = true
    // }, 3000)
)

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)


// ------------------------------Textures------------------------------
// main scene texture
const textureLoader = new THREE.TextureLoader()
const bakedTexture = textureLoader.load('/hangarBaked_final2.jpg')
bakedTexture.flipY = false
bakedTexture.encoding = THREE.sRGBEncoding
// this resolves issue with seams appearing as white lines
bakedTexture.minFilter = THREE.LinearFilter

// github icon scene texture
const textureLoader2 = new THREE.TextureLoader()
const bakedTexture2 = textureLoader2.load('/githubBaked_ready.jpg')
bakedTexture2.flipY = false
bakedTexture2.encoding = THREE.sRGBEncoding
bakedTexture2.minFilter = THREE.LinearFilter

// linkedin icon scene texture
const textureLoader3 = new THREE.TextureLoader()
const bakedTexture3 = textureLoader3.load('/linkedinBaked_ready.jpg')
bakedTexture3.flipY = false
bakedTexture3.encoding = THREE.sRGBEncoding
bakedTexture3.minFilter = THREE.LinearFilter

// email icon scene texture
const textureLoader4 = new THREE.TextureLoader()
const bakedTexture4 = textureLoader4.load('/emailBaked_ready.jpg')
bakedTexture4.flipY = false
bakedTexture4.encoding = THREE.sRGBEncoding
bakedTexture4.minFilter = THREE.LinearFilter


// ------------------------------Baked Material------------------------------
// main scene material
const bakedMaterial = new THREE.MeshBasicMaterial({map: bakedTexture})
const spaceshipEmissionMaterial = new THREE.MeshBasicMaterial({color: 0x4A8AFF})
const hangarEmissionMaterial = new THREE.MeshBasicMaterial({color: 0x7B818E})

// github icon scene material
const bakedMaterial2 = new THREE.MeshBasicMaterial({map: bakedTexture2})

// linkedin icon scene material
const bakedMaterial3 = new THREE.MeshBasicMaterial({map: bakedTexture3})

// email icon scene material
const bakedMaterial4 = new THREE.MeshBasicMaterial({map: bakedTexture4})

// remember to disable vertex colors when exporting to threejs or colors will be incorrect

// main scene load
gltfLoader.load(
    '/models/hangarScene2.glb',
    (gltf) =>
    {
        gltf.scene.traverse((child) =>
        {
            // outputs list of objects
            //console.log(child)
            child.material = bakedMaterial
        })

        const spaceshipEmissionMesh = gltf.scene.children.find(child => child.name === 'spaceshipEmission')
        const hangarEmissionMesh = gltf.scene.children.find(child => child.name === 'hangarEmission')

        spaceshipEmissionMesh.material = spaceshipEmissionMaterial
        hangarEmissionMesh.material = hangarEmissionMaterial

        scene.add(gltf.scene)
    }
)

// github icon scene load
const githubIcon = new THREE.Object3D();

gltfLoader.load('/models/githubIcon_2.glb', (gltf) =>
{
    gltf.scene.traverse((child) =>
    {
        // outputs list of objects
        //console.log(child)
        child.material = bakedMaterial2
    })
    githubIcon.add(gltf.scene)
    githubIcon.position.set(15, 1, 18.63)

    scene.add(githubIcon)
})

// linkedin icon scene load
const linkedinIcon = new THREE.Object3D();

gltfLoader.load('/models/linkedIcon_1.glb', (gltf) =>
{
    gltf.scene.traverse((child) =>
    {
        // outputs list of objects
        //console.log(child)
        child.material = bakedMaterial3
    })
    linkedinIcon.add(gltf.scene)
    linkedinIcon.position.set(15, 1.4, 15.65)


    scene.add(linkedinIcon)
})

// email icon scene load
const emailIcon = new THREE.Object3D();

gltfLoader.load('/models/emailIcon_1.glb', (gltf) =>
{
    gltf.scene.traverse((child) =>
    {
        // outputs list of objects
        //console.log(child)
        child.material = bakedMaterial4
    })
    emailIcon.add(gltf.scene)
    emailIcon.position.set(15, 1.35, 12)
    scene.add(emailIcon)
})


/**
 * Camera animation
 */
let tl = gsap.timeline()
let mixer = null


/**
 * Raycaster
 */
const raycaster = new THREE.Raycaster()
let currentIntersect = null


/**
 * Mouse click events
 */
const mouse = new THREE.Vector2()

// Resets fade-in animation so that it can occur for each click event
// function clickAnimationReset(elementId) {
//     var targetElement = document.getElementById(elementId);
//
//     // make sure fade duration and timeout duration match
//     targetElement.style.animation = 'fade 5s';
//
//     setTimeout(function (){
//         targetElement.style.animation = ''
//     }, 5000)
//
// }

window.addEventListener( 'mousemove', (event) =>
{
    mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

})

window.addEventListener('click', () =>
{
    // THIS WORKS..DON'T DELETE
    if(currentIntersect) {
        if (currentIntersect.object) {
            // console.log('click object')
            // console.log(currentIntersect.object)

            if (currentIntersect.object.name === "spynetTerminal" || currentIntersect.object.name === "spynetLoginScreen") {
                // clickAnimationReset('point-1')

                spynetLoginMesh.material.transparent = true

                // controls camera animation
                tl.to(camera.position, {
                    duration: 2,
                    x: 15.5,
                    y: 2,
                    z: 1.3,
                    onUpdate: function () {
                        controls.target = new THREE.Vector3(-13.65, 1.1, 1.24)
                        setTimeout(function () {
                            spynetLoginMesh.material.transparent = false
                        }, 45000)
                    }
                })
                playSound("access-granted")
            }
            else if (currentIntersect.object.name === 'aboutMe') {
                tl.to(camera.position, {
                    duration: 1,
                    x: 15,
                    y: 2,
                    z: -16,
                    onComplete: function () {
                        tl.to(camera.position, {
                            duration: 1,
                            x: 15.5,
                            y: 2,
                            z: 1.3
                        })
                        controls.target = new THREE.Vector3(-13.65, 1.1, 1.24)
                    }
                })
            }
            else if (currentIntersect.object.name === "menuSign") {
                // maybe make this display a help menu
                console.log("clicked menu sign")

                tl.to(camera.position, {
                    duration: 2,
                    x: -22.7,
                    y: 2,
                    z: -14,
                    onUpdate: function () {
                        controls.target = new THREE.Vector3(-22.85, 1.39, -10.01)
                    }
                })
            }
            else if (currentIntersect.object.name === "contact") {
                tl.to(camera.position, {
                    duration: 1.5,
                    x: 15,
                    y: 2,
                    z: -16,
                    onComplete: function () {
                        tl.to(camera.position, {
                            duration: 1.5,
                            x: 20,
                            y: 2,
                            z: 16.4,
                        })
                        controls.target = new THREE.Vector3(16, 2, 16)
                    }
                })
            }
            else if (currentIntersect.object.name === 'github') {
                window.open("https://github.com/Pmac101?tab=repositories")
            }
            else if (currentIntersect.object.name === 'linkedin') {
                window.open("https://www.linkedin.com/in/patrick-mccormick-0291a4196/")
            }
            else if (currentIntersect.object.name === 'email') {
                //window.location.href = 'email.html'
                var email = document.createElement("a");
                email.href = "mailto:pmac3187@gmail.com";
                email.click();

            }
            else if(currentIntersect.object.name === "spaceship") {
                playSound("systems-active")
            }
            else if(currentIntersect.object.name === "maintenanceTerminal" || currentIntersect.object.name === "maintenanceTerminalVideo") {
                playSound("life-systems")
            }
            else if(currentIntersect.object.name === "vehicle") {
                playSound("truck-horn")
            }
        }
    }
})


/**
 * Videos
 */
const video2 = document.getElementById('video2')
video2.play()
const video2Texture = new THREE.VideoTexture(video2)
video2Texture.minFilter = THREE.LinearFilter

const video2Mesh = new THREE.Mesh(
    new THREE.PlaneGeometry(4, 4,1,1),
    new THREE.MeshStandardMaterial({
        map: video2Texture,
        side: THREE.FrontSide,
    })
)
video2Mesh.position.set(-1.67, 1.71, -11.38)
video2Mesh.rotation.set(0,3.14,0)
video2Mesh.scale.set(0.38,0.16,1)
video2Mesh.name = "maintenanceTerminalVideo"
scene.add(video2Mesh)


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.33)
scene.add(ambientLight)

// const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
// directionalLight.castShadow = true
// directionalLight.shadow.mapSize.set(1024, 1024)
// directionalLight.shadow.camera.far = 15
// directionalLight.shadow.camera.left = - 7
// directionalLight.shadow.camera.top = 7
// directionalLight.shadow.camera.right = 7
// directionalLight.shadow.camera.bottom = - 7
// directionalLight.position.set(5, 5, 5)
// scene.add(directionalLight)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
//camera.position.set(-25, 4, -22)
camera.position.set(0,40,-1)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas1)
controls.target.set(0, 1, 0)
controls.enableDamping = true
// limits camera view to above ground
controls.maxPolarAngle = Math.PI/2
// limits panning
var minPan = new THREE.Vector3( -25, 0.5, -25);
var maxPan = new THREE.Vector3( 25, 15, 25 );
var _v = new THREE.Vector3();

controls.addEventListener("change", function() {
    _v.copy(controls.target);
    controls.target.clamp(minPan, maxPan);
    _v.sub(controls.target);
    camera.position.sub(_v);
})


/**
 * Sound effects
 */
function playSound(soundEffect) {
    const sound = document.getElementById(soundEffect);
    sound.play()
}


// ---------------------------------DEBUG SECTION---------------------------------
/**
 * Axes Helper
 */
// const axesHelper = new THREE.AxesHelper(2)
// scene.add(axesHelper)

/**
 * Debug UI
*/
// first parameter is the object that will be modified
// const objectPlacementFolder = gui.addFolder('Object Movement')
// objectPlacementFolder.add(sphereMesh.position, 'x').min(- 40).max(40).step(0.01).name('x-axis')
// objectPlacementFolder.add(sphereMesh.position, 'y').min(-40).max(40).step(0.01).name('y-axis')
// objectPlacementFolder.add(sphereMesh.position, 'z').min(- 40).max(40).step(0.01).name('z-axis')
//
// const objectRotationFolder = gui.addFolder('Object Rotation')
// objectRotationFolder.add(spynetLoginMesh.rotation, 'x', 0, Math.PI * 2).name('x-axis')
// objectRotationFolder.add(spynetLoginMesh.rotation, 'y', 0, Math.PI * 2).name('y-axis')
// objectRotationFolder.add(spynetLoginMesh.rotation, 'z', 0, Math.PI * 2).name('z-axis')
//
// const objectScaleFolder = gui.addFolder('Object Scale')
// objectScaleFolder.add(video2Mesh.scale, 'x', 0.1, 10, 0.01).name('x-axis')
// objectScaleFolder.add(video2Mesh.scale, 'y', 0.1, 10, 0.01).name('y-axis')
// objectScaleFolder.add(video2Mesh.scale, 'z', 0.1, 10, 0.01).name('z-axis')

// const lightingFolder = gui.addFolder('Lighting')
// lightingFolder.add(ambientLight, 'intensity', 0, 8, 0.01).name('ambient')
//
// gui.add(video2Mesh, 'visible').name('object visibility')
// gui.add(video2Mesh.material, 'wireframe').name('wireframe')

const controlDisplay = {
    left_click: "rotate",
    right_click: "pan",
    mouse_wheel: 'zoom'
}

const soundEffects = {
    mute: function () {
        var sound1 = document.getElementById('access-granted')
        var sound2 = document.getElementById('life-systems')
        var sound3 = document.getElementById('systems-active')
        var sound4 = document.getElementById('truck-horn')

        if (sound1.muted === true) {
            sound1.muted = false
            sound2.muted = false
            sound3.muted = false
            sound4.muted = false
        }
        else {
            sound1.muted = true
            sound2.muted = true
            sound3.muted = true
            sound4.muted = true
        }
    }
}

gui.add(controlDisplay, 'left_click')
gui.add(controlDisplay, 'right_click')
gui.add(controlDisplay, 'mouse_wheel')
gui.add(soundEffects, 'mute').name('Toggle sound')

// ------------disabled debug options------------
//gui.add(axesHelper, 'visible').name('axis helper')
//lightingFolder.add(directionalLight, 'intensity', 0, 10, .01).name('directional')
// ---------------------------------END DEBUG SECTION---------------------------------


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas1
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
//fixes discoloration
renderer.outputEncoding = THREE.sRGBEncoding


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    if(mixer)
    {
        mixer.update(deltaTime)
    }


    // Raycaster --------------------------------------------------------------
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(scene.children);

    if (intersects.length)
    {
        currentIntersect = intersects[0]

        // If/Else loop controls cursor hover effect
        if (currentIntersect.object.name === "spynetTerminal" || currentIntersect.object.name === "aboutMe" || currentIntersect.object.name === "menuSign" || currentIntersect.object.name === "contact" || currentIntersect.object.name === "spynetLoginScreen" || currentIntersect.object.name === "vehicle" || currentIntersect.object.name === "spaceship" || currentIntersect.object.name === "maintenanceTerminal" || currentIntersect.object.name === "maintenanceTerminalVideo" || currentIntersect.object.name === "github" || currentIntersect.object.name === "linkedin" || currentIntersect.object.name === "email") {
            console.log('hover is working')
            document.querySelector('.webgl').style.cursor = 'help'
        }
        else {
            document.querySelector('.webgl').style.cursor = ''
        }
    }
    // else
    // {
        // if(currentIntersect)
        // {
        //
        //     //console.log('mouse leave')
        // }

        // currentIntersect = null
    // }
    // End Raycaster--------------------------------------------------------------



    // Points--------------------------------------------------------------
    // if (sceneLoaded) {
    //     for (const point of points) {
    //         const screenPosition = point.position.clone()
    //         screenPosition.project(camera)
    //         // raycaster for points
    //         raycaster.setFromCamera(screenPosition, camera)
    //
    //         // if (intersects.length === 0) {
    //         //     point.element.classList.add('visible')
    //         // }
    //         // else {
    //         //     const intersectionDistance = intersects[0].distance
    //         //     const pointDistance = point.position.distanceTo(camera.position)
    //         //
    //         //     if (intersectionDistance < pointDistance) {
    //         //         point.element.classList.remove('visible')
    //         //     }
    //         //     else {
    //         //         point.element.classList.add('visible')
    //         //     }
    //         // }
    //
    //         const translateX = screenPosition.x * sizes.width * 0.5
    //         const translateY = - screenPosition.y * sizes.height * 0.5
    //         points.element.style.transform = `translate(${translateX}px, ${translateY}px)`
    //     }
    // }
    // End Points--------------------------------------------------------------

    // Update controls
    controls.update()

    // Icon and hologram rotations
    githubIcon.rotation.y += 0.005
    linkedinIcon.rotation.y += 0.005
    emailIcon.rotation.y += 0.005


    // Video updates
    video2Texture.needsUpdate = true

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()